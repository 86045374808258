import { h, createContext } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { Collection } from '../classes/Collection'

export const CollectionContext = createContext({})

export const CollectionProvider = ({ collectionid: initialCollectionid, children }) => {
  const [ state, setState ] = useState({
    pendingChanges: [],
    isLoading: false,
    isSaving: false,
    collectionId: initialCollectionid,
    collection: undefined,
  })

  const { pendingChanges, collectionid, collection } = state
  const set = (key, value) => setState(state => ({ ...state, [key]: value }))

  const setPendingChanges = pendingChanges => setState(state => ({ ...state, pendingChanges }))
  const setCollectionid = collectionid => setState(state => ({ ...state, collectionid }))
  const setCollection = collection => setState(state => ({ ...state, collection }))

  const verifyChanges = c => {
    const newChanges = c.allChanges
    // console.log('verifying changes', { newChanges, pendingChanges })

    if (newChanges.length !== pendingChanges.length) {
      // console.log('changes detected', newChanges)
      setPendingChanges(newChanges)
    }
  }

  useEffect(() => {
    if (collectionid) {
      const newCollection = window.collection = new Collection(collectionid, { onChange: verifyChanges })
      setState(state => ({ ...state, isLoading: true }))
      newCollection.load().then(() => {
        setState(state => ({ ...state, collection: newCollection, isLoading: false }))
      })
      .catch(err => {
        setState(state => ({ ...state, notFound: true, isLoading: false }))
      })
    }
  }, [collectionid])

  // local
  const getImage = path => { let img = new Image(); img.src = path; return img }

  const savePendingChanges = async () => {
    // console.log('saving pending changes (context)', collection.allChanges)
    set('isSaving', true)
    collection.savePendingChanges().then(c => {
      setState(state => ({
        ...state,
        isSaving: false,
        pendingChanges: c.allChanges,
      }))
    })
    .catch(err => {
      console.error(err.message)
      set('isSaving', false)
    })
  }

  return (
    <CollectionContext.Provider
      value={{
        refreshCollection: () => setCollection(collection),
        setCollection: setCollectionid,
        savePendingChanges,
        ...state
      }}>
      { children }
    </CollectionContext.Provider>
  )
}

export const useCollection = () => useContext(CollectionContext)
