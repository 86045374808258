import { h, Fragment } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import styled from 'styled-components'
import { useSearch } from '../../contexts/SearchContext'
import { useSettings } from '../../contexts/SettingsContext'

const StyledSearchBox = styled.input`
  color: var(--text-color);
  border-radius: 0;
  font-size: 1em;
  font-weight: lighter;
  font-family: sans-serif;
  letter-spacing: .01em;
  padding: .1em .7em .3em 0;
  background: none;
  border: 0;
  border-bottom: 2px dashed rgba(128,128,128,0.7);
  margin-left: .7em;
  flex: 1;
  width: calc(100% - 1rem);
  text-transform: lowercase;
  overflow: hidden;
`

const SearchGroup = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  transition: height 0.2s ease, margin 0.2s ease;
  font-size: 0.7em;
  flex: 0;

  overflow: ${props => props.visible ? 'visible' : 'hidden'};
  height: ${props => props.visible ? 'auto' : 0};
  margin: ${props => props.visible ? '0.2em 0.5em 0.2em' : 0};
`

const StyledSearchLabel = styled.label`
  font-size: 0.5em;
  font-weight: lighter;
  text-transform: uppercase;
  order: 0;
  flex: 0;
  order: -1;
  color: #07a2f7;
  // font-size: min(4vw, 3vh, 1em);

  span {
    font-size: 0;
  }

  &:before {
    content: 'what are you looking for?';
  }
`

const ClearSearch = styled.a`
  color: var(--text-color);
  font-size: 1em;
  font-weight: lighter;
  margin-left: 0.3em;
  transition: all 0.3s ease;
  opacity: 0.2;
`

export const SearchBox = ({ visible }) => {
  const { clearSearch, searchTerms, setSearchTerms, results, searchVisible } = useSearch()
  const { isEditing } = useSettings()
  const ref = useRef()

  console.log('SearchBox', { isEditing, searchVisible, visible })

  useEffect(() => {
    let keypressListener = (e) => {
      const key = String.fromCharCode(e.keyCode)
      if (/[a-zA-Z0-9-_ ]/.test(key)) {
        ref.current.focus()
      }
    }

    // setPageTransitions(true)
    window.addEventListener('keydown', keypressListener)

    if (!visible || isEditing) {
      window.removeEventListener('keydown', keypressListener)
    }

    return () => {
      window.removeEventListener('keydown', keypressListener)
    }
  }, [visible, isEditing])

  // autofocus when clicking search link
  useEffect(() => {
    if (searchVisible) {
      ref.current.focus()
    }
  }, [searchVisible])

  if (!visible) {
    return null
  }

  return (
    <SearchGroup visible={visible && searchVisible} className="search">
      <StyledSearchBox
        id="search"
        ref={ref}
        value={searchTerms}
        onChange={e => setSearchTerms(e.target.value)}
        placeholder="search this collection"
        />
      <StyledSearchLabel for="search"><span>search this collection</span></StyledSearchLabel>
      <ClearSearch onClick={clearSearch} className="clear-search">x</ClearSearch>
    </SearchGroup>
  )
}
