import { h, createContext } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import useStore from 'use-store'
import { auth } from '../utils/auth'

export const SettingsContext = createContext({})

export const SettingsProvider = ({ children }) => {
  const [ settings, setSettings ] = useState({
    isLoggedIn: auth.isLoggedIn,
    isEditing: false,
    pageTransitions: true,
  }, { persist: true })

  // fullscreen mode for image details pages
  const [ fullscreen, setFullscreen ] = useState(false)

  const setEditMode = (value) => setSettings({
    ...settings,
    isEditing: value !== undefined ? value : !settings.isEditing,
  })

  const setPageTransitions = (value) => settings.pageTransitions !== value && setSettings({
    ...settings,
    pageTransitions: value,
  })

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        isEditing: settings.isEditing && auth.isLoggedIn,
        setEditMode,
        toggleEditMode: () => setEditMode(),
        setPageTransitions,
        fullscreen,
        setFullscreen,
      }}>
      { children }
    </SettingsContext.Provider>
  )
}

export const useSettings = () => useContext(SettingsContext)
