import { h } from 'preact'
import styled from 'styled-components'

import { UserBar } from './UserBar'
import { useScrollTop } from '../../hooks/useScrollTop'

export const StyledPage = styled.div`
  position: ${props => props.simple ? 'relative' : 'absolute'};
  flex: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: ${({ visible }) => visible ? 'auto' : 'hidden'};
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Content = styled.article`
  padding: ${props => props.padding || '0'};
  flex: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  nav {
    margin-bottom: 0.5em;
  }

  ${props => props.maxWidth ? `max-width: 50rem; width: calc(100% - 4rem);` : ''}
`

export const Page = ({
  footer = false,
  padding = 0,
  styles = '',
  simple = false,
  header = false,
  className,
  maxWidth = false,
  scrollToTop = true,
  children,
  ...props
}) => {
  const ref = useScrollTop(scrollToTop)

  console.log('location', location.href, { header })

  return (
    <StyledPage 
      ref={ref} 
      padding={padding} 
      visible={true} 
      className={className} 
      header={header}
      simple={simple}
      >
      <Content 
        padding={padding} 
        maxWidth={maxWidth} 
        className="content"
        {...props}
        >
        { header && <UserBar /> }
        { children }
      </Content>
    </StyledPage>
  )
}