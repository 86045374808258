import { h, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { lazy, Suspense } from 'preact/compat'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Routes,
  useParams,
  useLocation,
} from 'react-router-dom'
import { CollectionProvider, useCollection } from './contexts/CollectionContext'
import { SettingsProvider } from './contexts/SettingsContext'
import { SearchProvider } from './contexts/SearchContext'
import { IndexAndDetails } from './components/IndexAndDetails'
import { Home } from './components/Home'
// import { Pages } from './components/Pages'
import { Missing } from './components/misc/Missing'
import { Lorem } from './components/misc/Lorem'
// import { Admin } from './components/Admin'
import { auth } from './utils/auth'
import { UserBar } from './components/misc/UserBar'
import { Footer } from './components/misc/Footer'

const Admin = lazy(() => import('./components/Admin'))
const Pages = lazy(() => import('./components/Pages'))
const AdminContext = lazy(() => import('./components/Admin/Contexts/AdminContext'))
const NavBar = lazy(() => import('./components/Admin/NavBar'))

// styles
import './App.scss'

const Main = styled.main`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  align-items: center;
`

const PageContent = () => {
  const { pathname } = useLocation()
  const isIndex = pathname === '/'
  const isBasicPage = pathname.indexOf('/p/') === 0// || pathname.indexOf('/my/') !== -1

  const content = (
    <Main>
      {/* { isIndex && <UserBar /> } */}
      { auth.isLoggedIn && <Suspense><NavBar /></Suspense> }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/p/:page" element={<Suspense><Pages /></Suspense>} />
        { auth.isLoggedIn && <Route path="/my/:page" element={<Suspense><Admin /></Suspense>} /> }
        <Route path="/:collectionid" element={<IndexAndDetails />} />
        <Route path="/:collectionid/details/:id" element={<IndexAndDetails />} />
        <Route path="*" element={<Missing />} />
      </Routes>
      { (isBasicPage || isIndex) && <Footer /> }
    </Main>
  )

  if (!auth.isLoggedIn) return content

  return (
    <Suspense>
      <AdminContext>
        { content }
      </AdminContext>
    </Suspense>
  )
}

export const App = () => {
  const [ refresh, setRefresh ] = useState(+new Date)

  return (
    <SettingsProvider>
      <CollectionProvider key={refresh}>
          <SearchProvider>
            <Router>
              <PageContent />
            </Router>
          </SearchProvider>
      </CollectionProvider>
    </SettingsProvider>
  )
}

export default App
