import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import styled from 'styled-components'
import { useSearch } from '../../contexts/SearchContext'
import { useFocus } from '../../hooks/useFocus'

const StyledMagnifyingGlass = styled.a`
  --size: max(1em, 3rem);
  height: var(--size);
  width: var(--size);
  transition: all 0.15s ease;
  position: relative;
  top: calc(-1.5 * var(--size));
  left: 1.5rem;
  margin-bottom: calc(-1.3 * var(--size));
  // opacity: 1;
  // flex: 0;
  // order: -1;
  // display: block;
  // position: relative;
  // top: -1.5em;
  // margin-bottom: -1.2em;
  // margin-left: 1rem;
  opacity: 0.2;

  svg {
    height: 100%;
    fill: var(--text-color);
    transition: all 0.15s ease;
  }

  &:hover {
    opacity: 1;
    svg {
      transform: scale(1.1);
    }
  }
`

export const SearchLink = () => {
  const { searchVisible, setSearchVisible } = useSearch()

  return (
    <StyledMagnifyingGlass 
      onClick={() => setSearchVisible(!searchVisible)} 
      visible={searchVisible} 
      className="search-link">
      <svg 
        focusable="false" 
        preserveAspectRatio="xMidYMid meet" 
        viewBox="0 0 24 24">
          <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z" />
      </svg>
    </StyledMagnifyingGlass>
  )
}