import styled from 'styled-components'

const SPACE_BETWEEN = '0.6em'

export const HorizontalList = styled.div`
  display: ${props => props.hide ? 'none' : 'flex'};
  align-items: center;
  flex-flow: row wrap;

  label {
    margin-top: 0.2em;
  }

  > * {
    white-space: nowrap;
  }

  &:not(:first-child) {
    margin-top: 0.5em;
  }

  > *:not(:last-child) {
    padding-right: ${SPACE_BETWEEN};
  }

  > *:not(:last-child) {
    border-right: 1px solid rgba(0,0,0,0.1);
    margin-right: ${SPACE_BETWEEN};
  }
`