const AUTH_ROOT = 'https://auth.slick.af'

const getCookies = () => decodeURIComponent(document.cookie).split(/;\s*/).reduce((acc, c) => {
  let [ key, value ] = c.split('=')
  return (acc[key] = value) && acc
}, {}) || {}

const getQuery = (url = new URL(window.location.href)) => Object.fromEntries(url.searchParams.entries())

class Auth {
  constructor() {
    this.$ = { ...getCookies(), ...getQuery() }
    this.timer = undefined

    // console.log(this.$)

    // happens on refresh
    if (this.isLoggedIn && !this.token) {
      this.trulyLoggedIn()
    }

    // happens at first login after auth redirect
    if (this.token && !this.isLoggedIn) {
      this.loginFromToken(this.token, this.ttl)
      history.replaceState(null, null, window.location.origin + window.location.pathname)
    }
  }

  get isExistingUser() {
    return !!this.$.isExistingUser
  }

  get isLocal() {
    return window.location.href.includes('localhost')
  }

  get isLoggedIn() {
    return !!this.$.isLoggedIn
  }

  get ttl() {
    return Number(this.$.ttl || 60)
  }

  loginAction(e) {
    let url = new URL(window.location.href)
    let returnTo = url.pathname === '/p/login' ? window.location.href.replace('/p/login', '/my/collections') : window.location.href
    e && e.preventDefault()
    console.log('logging in...')
    window.location.href = AUTH_ROOT + '/dropbox/login?returnTo=' + encodeURIComponent(returnTo)
  }

  trulyLoggedIn() {
    // console.log('ensuring truly logged in...')
    return this.refreshed || (this.refreshed = this.refresh())
  }

  get token() {
    return this.$.jwt
  }

  loginFromToken(jwt, ttl = 60, refresh) {
    if (!jwt) {
      throw new Error('Could not login without a token')
    }

    // console.log('logging in from token', jwt, ttl)

    this.$.jwt = jwt
    this.$.ttl = ttl
    // instantly clear cookies before continuing - now jwt and ttl live only in memory
    document.cookie = `jwt=; max-age=0; path=/; domain=.slick.af;`
    document.cookie = `ttl=; max-age=0; path=/; domain=.slick.af;`


    if (this.isLocal && refresh || this.$.refresh) {
      document.cookie = `refresh=${refresh || this.$.refresh}; max-age=${this.ttl}; path=/;`
    }

    // add a cookie to persist logged in state

    if (!this.isLoggedIn) {
      console.log('logging in...')
    }

    this.$.isLoggedIn = true
    document.cookie = `isLoggedIn=true; max-age=${ttl}; path=/;`
    document.cookie = `isExistingUser=true; path=/;`
    this.scheduleRefresh(ttl)
  }

  async logoutAction(e) {
    console.log('logging out...', this)
    const url = new URL(window.location.href)
    e && e.preventDefault()
    delete this.$.isLoggedIn
    this.$ = {}
    document.cookie = `isLoggedIn=; max-age=0; path=/;`
    document.cookie = `jwt=; max-age=0; path=/; domain=.slick.af;`
    document.cookie = `ttl=; max-age=0; path=/; domain=.slick.af;`
    document.cookie = `refresh=; max-age=0; path=/;`
    clearTimeout(this.timer)

    await fetch(AUTH_ROOT + '/logout', { credentials: 'include' })
      .then(r => {
        if (r.ok) {
          console.warn('logged out successfully')
        } else {
          console.error('failure to log out')
        }
      })
      .catch(() => {
        console.warn('could not log out properly...')
      })

    if (url.pathname.match(/^\/user\//)) {
      window.location.href = window.location.origin
    } else {
      console.log('attempting to refresh on logout')
      window.location.href = window.location.href
    }
  }

  async refresh() {
    let refreshPath = AUTH_ROOT +'/refresh'

    if (this.isLocal) {
      refreshPath += '?refresh=' + this.$.refresh
    }

    return fetch(refreshPath, {
      credentials: 'include'
    })
      .then(r => r.json())
      .then(({ jwt, ttl, refresh }) => this.loginFromToken(jwt, ttl, refresh))
      .catch(err => {
        console.warn('refresh failed... logging out')
        this.logoutAction()
      })
  }

  requireLogin() {
    !this.isLoggedIn && this.loginAction()
  }

  async waitForRefresh() {
    if (this.isLoggedIn && !this.token) {
      await this.trulyLoggedIn()
    }

    return true
  }

  scheduleRefresh() {
    const delay = this.ttl * 0.95
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.refresh(), delay * 1000) // refresh with 10% leeway
  }
}

export const auth = window.auth = new Auth()
