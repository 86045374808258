import { h, Fragment } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import styled from 'styled-components'

export const StyledToggle = styled.input`
  padding-left: 2em;
  line-height: 1em;
  cursor: pointer;
  color: #ccc;
  position: relative;
  font-size: 1.2rem;
  height: ${props => props.height || '1.5em'};
  width: ${props => props.width || '2.5em'};
  border: 1px solid yellow;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #777;
    border: 1px solid #222;
    border-radius: calc(${props => props.radius || 0.2} * ${props => props.height || '1.5em'});
    z-index: 0;
  }

  &:checked:before {
    background-color: #66a0dc;
  }

  // handle
  &:after {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 0.9em;
    top: 0.13em;
    bottom: 0.06em;
    left: 0.2em;
    border: 1px solid #222;
    border-radius: calc(${props => (props.radius || 0.2) * 0.6} * ${props => props.height || '1.5em'});
    transition: all 0.1s ease;
  }

  &:checked:after {
    left: calc(100% - 1.1em);
  }

  &:checked ~ * {
    color: #4684c3;
  }
`

export const Toggle = ({
  checked = false,
  onChange = () => {},
  ...props
}) => {
  const [ value, setValue ] = useState(checked)
  const toggleHandler = () => {
    onChange(!value)
    setValue(!value)
  }

  return (
    <Fragment>
      <StyledToggle
        checked={value}
        onChange={toggleHandler}
        {...props}
        />
    </Fragment>
  )
}