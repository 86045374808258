import { h } from 'preact'
import styled from 'styled-components'
import { Link } from './NoFocusLink'

import { HorizontalList } from './HorizontalList'

const StyledFooter = styled.footer`
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: min(1rem, 5vmin);
  padding: 1em 1em 2em;
  flex: 0 3rem;
  min-height: 5rem;
  // background-color: salmon;
  justify-content: flex-end;

  a {
    color: #565656;
  }

  a:hover {
    color: #222;
  }
`

const StyledHorizontalList = styled(HorizontalList)`
  justify-content: center;

  > * {
    margin-bottom: 0.5em;
  }
`

const Version = styled.em`
  font-style: normal;
  display: block;
  margin-top: 0.1em;
  opacity: 0.6;
  font-size: 0.7em;
`

const version = ([ ...document.getElementsByTagName('meta') ]).find(m => m.name === 'version')

export const Footer = () => {
  return (
    <StyledFooter>
      <StyledHorizontalList>
        <Link to="/">Home</Link>
        <Link to="/p/about">About</Link>
        <Link to="/p/support">Support</Link>
        <Link to="/p/terms">Terms</Link>
      </StyledHorizontalList>
      <Version aria-hidden="true">
        &copy; 2021 Slick &mdash; v{ version && version.content }
      </Version>
    </StyledFooter>
  )
}
