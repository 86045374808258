import { h } from 'preact'
import { memo } from 'preact/compat'
import styled from 'styled-components'
import { GridItem } from './GridItem'

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.amount === 'few' ? 29 : 17 }rem, 1fr));
  grid-gap: 0.2rem;
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;
  padding: 0.5rem;

  // &::before {
  //   content: '';
  //   width: 0;
  //   padding-bottom: 100%;
  //   grid-row: 1 / 1;
  //   grid-column: 1 / 1;
  // }

  // & > :first-child {
  //   grid-row: 1 / 1;
  //   grid-column: 1 / 1;
  // }

  @media (max-width: 90rem) {
    grid-template-columns: repeat(auto-fill, minmax(${props => props.amount === 'few' ? 22.5 : 13 }rem, 1fr));
    // background-color: red;
  }

  @media (max-width: 30rem) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    // background-color: cyan;
  }
`

export const Grid = memo(({ items, showOnly }) => {
  const amount = items.length < 8 ? 'few' : 'average'

  // console.log('rendering grid', { items, showOnly })

  return (
    <StyledGrid amount={amount} className="grid">
      {
        showOnly.map((item, i) =>
          <GridItem key={item.id} item={item} visible={showOnly.includes(item)} />
        )
      }
    </StyledGrid>
  )
})