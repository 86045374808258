import { h, Fragment } from 'preact'
import { useRef } from 'preact/hooks'
import styled from 'styled-components'

const StyledWithToolTip = styled.div`
  position: relative;

  ${props => props.styles}

  caption {
    // display: none;
    position: absolute;
    background-color: #000;
    padding: 0.2rem 0.5rem;
    font-size: 0.65rem;
    line-height: 1.3rem;
    color: #eee;
    text-transform: uppercase;
    top: 120%;
    ${props => props.direction}: 0;
    transform: scale(0);
    opacity: 0;
    transition: all 0.15s;
    text-align: left;
    border-radius: 0.4rem;
    border: 1px solid #ccc;
    box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.5);
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 100;
  }

  &:hover {
    // svg {
    //   transform: scale(1.08);
    // }

    caption:not(:empty) {
      display: block;
      transform: scale(1);
      opacity: 1;
    }
  }
`

const whichSide = (el) => {
  if (!el) return 'left'
  let { x, y } = el.getBoundingClientRect()
  let { width } = document.body.getBoundingClientRect()

  return x > width * 0.5 ? 'right' : 'left'
}

export const WithTooltip = ({
  svg,
  tooltip = '',
  children,
  styles = '',
  // scaleSvgOnHover =
  ...props
}) => {
  const ref = useRef(null)
  const SvgIcon = svg
  window.logout = ref.current

  return (
    <StyledWithToolTip ref={ref} direction={whichSide(ref.current)} styles={styles}>
      <>{ children }</>
      <caption>{ tooltip }</caption>
    </StyledWithToolTip>
  )
}