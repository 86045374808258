import { h, Fragment } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import styled from 'styled-components'
import nmd from 'nano-markdown'

import { lazy, Suspense } from 'preact/compat'
import { auth } from '../../utils/auth'
import { Lorem } from './Lorem'

const EditField = lazy(() => import('../Admin/Misc/EditField'))

const toMarkdown = window.toMarkdown = str => {
  if (!str) return undefined

  let transformed = str.replace(/(?:\n)>\s+([^\n]+)/gi, '\n<blockquote>$1</blockquote>')

  // console.log('TRANSFORMED AFTER BLOCKQUOTE', transformed)
  transformed = nmd(transformed)

  // console.log('TRANSFORMED', transformed)

  return transformed
}

const StyledStory = styled.section`
  margin-right: 1em;
  padding-bottom: 2em;

  &:focus {
    outline: none;
  }

  @media (min-width: 60rem) and (min-height: 30rem) {
   ${
      props => props.landscape
      ? `
        columns: 2;
        column-gap: 6rem;
        column-rule: 1px dotted #ccc;
        padding-right: 2rem;
        column-fill: auto;`
      : null
    }
  }
`

// const observer = new MutationObserver(changes => console.log('Observer', changes))

export const Story = ({ item }) => {
  const story = item.toJSON().story
  const content = (
    <StyledStory landscape={item.isLandscape} editing={true} contentEditable={false} className="story" innerHTML={toMarkdown(story)}>
      {
        !story && item.getCollection().toJSON().lorem && <Lorem />
      }
    </StyledStory>
  )

  if (auth.isLoggedIn) {
    return (
      <Suspense>
        <EditField
          item={item}
          value={story}
          original={story}
          placeholder="Why don't you write the story behind the image? There is (almost) no limit to the amount you can write here..."
          updateHandler={story => item.update({ story })}
          rows={10}
          textStyle={`
            margin-bottom: 1.5em;
            padding: 0.3em 0.3em 0.25em;
            line-height: 1.6em;
            font-weight: lighter;
          `}
          >
          { content }
        </EditField>
      </Suspense>
    )
  }

  return content
}
