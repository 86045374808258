import { h, createContext } from 'preact'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { useCollection } from './CollectionContext'

export const SearchContext = createContext({})

const filterBy = (terms = [], items = []) => {
  const match = terms => item => {
    let tokens = item.getSearchTokens()

    for (var term of terms) {
      let passed = false

      for (var token of tokens) {
        if (token.indexOf(term) === 0) {
          passed = true
          break
        }
      }

      if (!passed) return false
    }

    return true
  }

  let filtered = items.filter(match(terms))

  // console.log({ filtered, items })

  return filtered
}

export const SearchProvider = ({ children }) => {
  const { collection } = useCollection()
  // const [ searchTerms, setSearchTerms ] = useState('')
  // const [ searchVisible, setSearchVisible ] = useState(false)
  const searchableItems = collection ? collection.items : []
  // const [ results, setResults ] = useState(searchableItems)

  const [ state, setState ] = useState({
    searchTerms: '',
    searchVisible: false,
    results: searchableItems,
  })

  const { searchTerms, searchVisible, results } = state

  const stateSetter = what => value => setState(state => ({
    ...state,
    [what]: value,
  }))

  useEffect(() => {
    if (!searchTerms) {
      setState(state => ({
        ...state,
        results: searchableItems,
        searchVisible: false,
      }))
    } else {
      let terms = searchTerms.toLowerCase().split(' ')
      let newResults = filterBy(terms, searchableItems)
      setState(state => ({
        ...state,
        results: newResults,
        searchVisible: true,
      }))
    }
  }, [searchTerms, collection])

  const setSearchTerms = stateSetter('searchTerms')

  return (
    <SearchContext.Provider
      value={{
        ...state,
        setSearchTerms,
        setSearchVisible: stateSetter('searchVisible'),
        clearSearch: () => setState(state => ({ ...state, searchVisible: false, searchTerms: '' })),
      }}
      >
      { children }
    </SearchContext.Provider>
  )
}

export const useSearch = () => useContext(SearchContext)
