import { h } from 'preact'
import { memo } from 'preact/compat'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../misc/Button'
import {
  CONTROLS_HEIGHT,
  MOBILE_CONTROLS_HEIGHT,
  MOBILE_MAXWIDTH
} from '../../constants'
import { useCollection } from '../../contexts/CollectionContext'
import { useSettings } from '../../contexts/SettingsContext'

const StyledControls = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  opacity: 1;
  font-size: min(3.5rem, 11vw);
  height: calc(var(--page-controls-height) - min(0.6em, 0.35rem));
  border-bottom: min(0.6em, 0.35rem) solid var(--background-color);
  box-shadow: 0 0.2em 0.5em -0.4em var(--text-color);
  position: fixed;
  width: 50%;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    background-color: var(--text-color);
    opacity: 0.2;
    left: 0;
    right: 0;
    height: 110%;
    z-index: -1;
  }

  > * {
    color: var(--background-color);

    &:first-child {
      padding-left: 1em;
    }

    &:last-child {
      padding-right: 1em;
    }
  }

  & + * {
    top: var(--page-controls-height);
  }
`

const AntiFocusButton = ({ children, ...props }) => {
  const { pageTransitions, setPageTransitions } = useSettings()
  const defocus = e => {
    e.target.blur()
    setPageTransitions(true)
  }

  return (
    <Button
      as={Link}
      {...props}
      onClick={defocus}
      >
      { children }
    </Button>
  )
}

const StyledIndexLink = styled.span`
  font-size: max(3vmin, 0.4em);
  text-transform: lowercase;
  padding: 0.5em;
  line-height: 0.8em;

  @media (max-width: 45rem) {
    flex: 0;
    font-size: 0.3em;
  }
`

const StyledPrevAndNext = styled.span`
  transform: scale(1.5);
  letter-spacing: -0.09em;
  line-height: 1em;
`

export const Controls = memo(({ prev, next, collection }) => {
  const { getIndexPath } = useCollection()

  return (
    <StyledControls className="controls">
      <AntiFocusButton disabled={!prev} to={prev && prev.path || ''} className="prev">
        <StyledPrevAndNext>prev</StyledPrevAndNext>
      </AntiFocusButton>
      <AntiFocusButton to={collection.path} className="return">
        <StyledIndexLink>Return to Index</StyledIndexLink>
      </AntiFocusButton>
      <AntiFocusButton disabled={!next} to={next && next.path || ''} className="next">
        <StyledPrevAndNext>next</StyledPrevAndNext>
      </AntiFocusButton>
    </StyledControls>
  )
})
