import { h } from 'preact'
import { memo } from 'preact/compat'
import styled from 'styled-components'
import { randomItem } from 'supergeneric/collections'

const StyledLoading = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ccc;
  background-color: #fff;
  z-index: 1;
  
  h1 {
    letter-spacing: -0.04em;
    line-height: 1.1em;
  }
`

export const Loading = memo(() => {
  const message = randomItem([
    'counting daisies',
    'dreaming of untold riches and/or kittens',
    'really overthinking things',
    'eating your browser cookies',
    'applying to jobs in the Galactic Federation',
    'investigating the meaning of life',
  ])

  return (
    <StyledLoading>
      <h1>{ message }...</h1>
    </StyledLoading>
  )
})
