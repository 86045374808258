export class Editable {
  constructor(base = {}, options = {}) {
    Object.assign(this, base)
    this.$ = {
      changes: {},
      isDirty: false,
      resets: {},
    }

    this.set(base)
  }

  get isDirty() {
    return this.$.isDirty
  }

  get changes() {
    const isEmpty = v => !v || (v.constructor.name === 'Array' && (!v.length || !v.filter(i => i).length))

    return Object.entries(this.$.changes).reduce((acc, [key, value]) => {
      if (isEmpty(value) && isEmpty(this[key])) return acc
      if (value !== this[key]) acc[key] = value
      return acc
    }, {})
  }

  dirtyCheck() {
    const a = this.toJSON({ withChanges: false })
    const b = this.changes

    this.$.isDirty = Object.keys(b).some(key => JSON.stringify(a[key]) !== JSON.stringify(b[key]))

    return this
  }

  update(changes = {}) {
    Object.assign(this.$.changes, changes)
    this.dirtyCheck()
    this.onChange && this.onChange(this)

    return this
  }

  set(base = {}) {
    Object.assign(this, base)

    return this
  }

  clearChanges() {
    this.$.changes = {}
    this.$.isDirty = false

    return this
  }

  get changeAction() {
    if (!this.isDirty) return undefined

    return [
      this.id ? 'update' : 'create',
      this.$.type,
      this.id,
      this.changes,
    ]
  }

  async persistChanges() {
    if (!this.isDirty) return false

    this.set(this.changes)
    this.clearChanges()

    return this
  }

  toJSON(options = {}) {
    const { withChanges = true } = options
    const { $, ...base } = this
    return { ...base, ...(withChanges ? this.changes : {}) }
  }
}

/*

const editable = new Editable({ foo: 'bar' })

editable.isDirty // false
editable.toJSON() // { foo: 'bar' }

editable.update({ age: 13 })
editable.isDirty // true
editable.toJSON() // { foo: 'bar', age: 13 }
editable.toJSON({ withChanges: false }) // { foo: 'bar' }

await editable.save()
editable.isDirty // false
editable.toJSON() // { foo: 'bar', age: 13 }
editable.toJSON({ withChanges: false }) // { foo: 'bar', age: 13 }

editable.update({ age: 12 })
editable.isDirty // true
editable.toJSON() // { foo: 'bar', age: 12 }
editable.clearChanges()
editable.isDirty // false
editable.toJSON() // { foo: 'bar', age: 13 }

*/