import { fetcher } from './DataFetcher'
import { Editable } from './Editable'
import { authenticated } from '../utils/authenticated'
import { auth } from '../utils/auth'

const WIDTH_BEYOND_SQUARE = 0.8

export class CollectionItem extends Editable {
  constructor(base = {}, collection) {
    super(base)
    this.$.collection = collection
    this.$.type = 'image'
  }

  get orientation() {
    if (!this.ratio) return undefined
    if (this.ratio > (1 + WIDTH_BEYOND_SQUARE)) return 'wide'
    if (this.ratio > 1) return 'horizontal'
    if (this.ratio < (1 - WIDTH_BEYOND_SQUARE)) return 'tall'
    if (this.ratio < 1) return 'vertical'
    return 'square'
  }

  get path() {
    return `/${this.$.collection.id}/details/${this.id}`
  }

  get imagePath() {
    return `https://api.slick.af/images/${this.id}.jpg`
  }

  get previewPath() {
    return `https://api.slick.af/images/${this.id}.jpg?size=small`
  }

  get isHorizontal() {
    return this.orientation === 'horizontal' || this.orientation === 'wide'
  }

  get isTall() {
    return this.orientation === 'tall'
  }

  get isVertical() {
    return this.orientation === 'vertical' || this.orientation === 'tall'
  }

  get isWide() {
    return this.orientation === 'wide'
  }

  get isSquare() {
    return this.orientation === 'square'
  }

  getCollection() {
    return this.$.collection
  }

  getSearchTokens() {
    return [
      ...(this.group || '').toLowerCase().split(' '),
      ...(this.title || '').toLowerCase().split(' '),
      ...(this.subtitle || '').toLowerCase().split(' '),
      ...(this.keywords || []),
      this.isHorizontal && ['is:horizontal', 'is:wide'],
      this.isVertical && ['is:vertical', 'is:tall'],
      this.isSquare && 'is:square',
      this.orientation === 'wide' && 'is:pano',
      this.orientation === 'tall' && 'is:tall',
    ].filter(v => !!v).flat()
  }

  getDetails() {
    return fetcher
      .get(`/images/${this.id}`, undefined, { nocache: auth.isLoggedIn })
      .then(payload => this.set(payload))
      .catch(err => console.error(err))
  }

  getIndex(within = this.collection.items) {
    return within.indexOf(this)
  }

  getNext(within = this.collection.items, direction = 1) {
    let thisIndex = within.indexOf(this)

    return thisIndex !== -1 ? within[thisIndex+direction] : undefined
  }

  getPrev(within, direction = -1) {
    return this.getNext(within, direction)
  }

  update(...args) {
    super.update(...args)
    this.$.collection.onChange && this.$.collection.onChange(this.$.collection)
  }
}