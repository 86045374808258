export class DataFetcher {
  map = {}

  get(location, fetchOptions = {}, options = {}) {
    const { nocache = false } = options
    const { method = 'GET' } = fetchOptions

    if (location.match(/^\//)) {
      location = 'https://api.slick.af' + location
    }
    const key = location + JSON.stringify(fetchOptions)

    if (this.map[key] && method === 'GET') {
      console.log('returning cached data from DataFetcher', key)
      return this.map[key]
    }

    let fetcher = fetch(location, fetchOptions)
      .then(r => {
        if (!r.ok) {
          throw new Error(`Error fetching data from ${location}`)
        }
        return r
      })
      .then(r => {
        if (r.status === 409) return false
        if (r.status === 204) return true

        const isJSON = (r.headers.get('content-type') || '').includes('json')

        return isJSON ? r.json() : r.text()
      })

    if (!nocache) this.map[key] = fetcher

    return fetcher
  }
}

export const fetcher = new DataFetcher()
