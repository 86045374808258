import { useRef, useEffect } from 'preact/hooks'

 // pass in id to only trigger scroll top when new id loaded
export const useScrollTop = (scroll) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current && scroll) {
      // console.log('scrolling page to top', ref.current)
      ref.current.scrollTop = 0
      // ref.current.focus()
    }
  })

  return ref
}