import { h } from 'preact'
import { memo } from 'preact/compat'
import { useRef } from 'preact/hooks'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Pane } from './Pane'
import { PAGE_TRANSITIONS } from '../../constants'
import { useSettings } from '../../contexts/SettingsContext'

const StyledGroup = styled.div`
  position: absolute;
  width: 100%;
  // height: 100%;
  transition: ${props => props.transitions ? `transform ${PAGE_TRANSITIONS} ease` : 'none'};
  transform: translate3D(${props => -props.offset * 100}%, 0, 0);
  bottom: 0;
`

export const Group = memo(({ targets, items }) => {
  const { pageTransitions } = useSettings()
  const [ , target, ] = targets
  const targetRef = useRef(target)

  // track target for next comparison
  targetRef.current = target

  return (
    <StyledGroup offset={target.getIndex(items)} transitions={pageTransitions}>
      {
        targets.map((item, i) =>
          item
          ? <Pane
              key={item.id}
              item={item}
              middle={i === 1}
              visible={target === item}
              items={items}
              />
          : null
        )
      }
    </StyledGroup>
  )
})
