import { fetcher } from '../classes/DataFetcher'
import { auth } from './auth'

const ADMIN_ROOT = 'https://admin.slick.af'

class Authenticated {
  async getProfile() {
    if (this.profile) return this.profile

    return this.profile = await this.fetch('/my/profile')
      .catch(err => {
        console.warn('profile fetch failed... logging out')
        this.logoutAction()
      })
  }

  async getCollections(options = {}) {
    // if (this.collections) return this.collections

    return this.collections = await this.fetch('/my/collections', options)
      .catch(err => {
        console.warn('collections fetch failed... logging out')
        this.logoutAction()
      })
  }

  fetch(path, options = {}) {
    const { nocache } = options
    const jwt = options.token || auth.token
    const fetchOptions = jwt ? {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
        Authorization: `Bearer ${jwt}`,
      }
    } : options

    // console.log('Authenticated fetch', { path, fetchOptions, jwt })
    return fetcher.get(ADMIN_ROOT + path, fetchOptions, { nocache })
  }
}

export const authenticated = window.authenticated = new Authenticated()
