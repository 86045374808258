import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Link } from 'react-router-dom'
import { lazy, Suspense } from 'preact/compat'
import { randomItem } from 'supergeneric/collections'
import styled from 'styled-components'
import { auth } from '../../utils/auth'
import { Logo } from '../misc/Logo'

const NavBar = lazy(() => import('../Admin/NavBar'))

const Header = styled.nav`
  font-size: min(1.2rem, 6vmin);
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.spread {
    --padding-sides: 1em;
    // min-height: 3.3rem;
    left: 0;
    right: 0;
    padding: 0.5em var(--padding-sides);
    width: calc(100% - var(--padding-sides) * 2);
  }

  ${props => props.spread && ``}
`

const Links = styled.section``

const StyledLogo = styled(Logo)`

  path {
    transition: 1s all ease;

    &:hover {
      fill: var(--slick-pink);
    }
  }
`

const LogoLink = styled(Link)`
  font-size: 2em;
  width: 1em;
  height: 1em;
  overflow: hidden;
  line-height: 1em;
`

export const UserBar = ({
  visible = true,
  spread = false,
  ...props
}) => {
  if (!visible) return null

  return (
    <Header className={spread && 'spread'} {...props}>
      <LogoLink to="/"><StyledLogo color="#ddd" />Return to Home</LogoLink>

      <Links>
        { !location.href.includes('/p/login') && <Link to="/p/login">Sign In{ !auth.isExistingUser && ' / Join' }</Link> }
      </Links>
    </Header>
  )
}
