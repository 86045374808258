import styled from 'styled-components'

export const Centered = styled.section`
  display: flex;
  // // height: calc(100vh - 6em);
  // // height: 100%;
  flex-flow: column;
  justify-content: center;
  // align-content: center;
  align-items: center;
  // text-align: center;
  // padding: 0 2em 0;
`
