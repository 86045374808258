import { h } from 'preact'
import { memo, useEffect, useState } from 'preact/compat'
import styled from 'styled-components'
import { Grid } from './Grid'
import { SearchBox } from './SearchBox'
import { Title } from './Title'
import { PAGE_TRANSITIONS } from '../../constants'
import { useSearch } from '../../contexts/SearchContext'
import { randomItem } from 'supergeneric/collections'

const StyledGroup = styled.section`
  font-size: min(8vw, 6vh, 2.5rem);
  font-weight: lighter;
  line-height: 1.2em;
  letter-spacing: -0.0.1em;
  color: var(--text-color);
  max-width: 70rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 2em;
`

const StyledLabel = styled.label`
  text-transform: uppercase;
  color: var(--text-color);
  opacity: 0.3;
  font-size: 0.8em;
  font-weight: normal;
`

const StyledContent = styled.section`
  letter-spacing: -0.037em;
  line-height: 1.3em;
`

export const NoResults = memo(({ visible, items, showOnly = items }) => {
  const { searchTerms, setSearchTerms, results } = useSearch()
  const [ saying, setSaying ] = useState()

  useEffect(() => {
    if (!results.length) {
      setSaying(
        randomItem([
          'Searching for things that exist are statistically 99.8% more likely to return results.',
          'Most words in the English alphabet begin with a letter.',
          'Searching for that matches exactly zero results.',
          `Mermaids aren't technically real.`,
          'This entire code for this site (before images) is under 50KB in size. #nerdflex',
          'You will *never* see ads on this site.',
          'Tons of apps are cheaper than a cup of coffee these days. At the price of free, this one is cheaper still.',
          'Searching for things tends to produce the most reliable search results, yet many times, the results are unreliable.',
        ])
      )
    }
  }, [results.length])

  return (
    <StyledGroup>
      <StyledLabel>Did You Know?</StyledLabel>
      <StyledContent>{ saying}</StyledContent>
    </StyledGroup>
  )
})