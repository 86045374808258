import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'

const observer = (getVisibility, setVisibility) => entries => {
  if (entries[0].intersectionRatio <= 0) return false

  if (!getVisibility()) {
    setVisibility(true)
  }
}

export const useHasBeenVisible = () => {
  const ref = useRef(null)
  const visibleRef = useRef(null)
  const [ hasBeenVisible, setHasBeenVisible ] = useState(false)
  const getVisibility = () => hasBeenVisible
  const intersectionObserver = new IntersectionObserver(observer(getVisibility, setHasBeenVisible))

  useEffect(() => {
    intersectionObserver.observe(ref.current)

    return () => {
      // console.log('unobserving item (useHasBeenVisible)')
      intersectionObserver.unobserve(ref.current)
    }
  }, [hasBeenVisible])

  return {
    ref,
    hasBeenVisible,
  }
}