import { h, Fragment } from 'preact'
import styled from 'styled-components'
import { Toggle } from '../Admin/Misc/Toggle'
import { WithTooltip } from '../Admin/Misc/WithTooltip'
import { useCollection } from '../../contexts/CollectionContext'

const StyledSettings = styled.section`
  font-size: 1rem;
  display: flex;
  flex-flow: row-reverse;
  padding: 0.2rem 1rem 0.5rem;
`

export const Settings = ({
}) => {
  const { collection } = useCollection()
  const { items, luts, images, ...other } = collection

  return (
    <StyledSettings>
      <WithTooltip tooltip="adds filler text to entries without stories">
        <label className="flex row center">
          <Toggle
            radius={0.3}
            height="1.2em"
            width="2em"
            type="checkbox"
            checked={collection.toJSON().lorem}
            onChange={() => collection.update({ lorem: collection.toJSON().lorem ? false : true })}
            />
          <span>Add Filler Text</span>
        </label>
      </WithTooltip>
      {/* <pre>
        { JSON.stringify(other, null, 2) }
      </pre> */}
    </StyledSettings>
  )
}