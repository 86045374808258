import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { isMobile } from '../utils/isMobile'
import { useSettings } from '../contexts/SettingsContext'

export function useFocus(options = {}) {
  const { isEditing } = useSettings()
  let ref = useRef(null)
  let [ isFocused, setIsFocused ] = useState(false)
  let [ active, setActive ] = useState(false)

  let {
    autofocus = !isMobile() && !isEditing,
    maintainFocus = !isMobile() && !isEditing,
  } = options

  useEffect(() => {
    let el = ref.current

    autofocus && el && el.focus({
      preventScroll: true
    })

    let onFocus = (e) => {
      setIsFocused(true)
    }

    let onBlur = (e) => {
      if (maintainFocus) {
        setTimeout(() => {
          el && el.focus({
            preventScroll: true
          })
        }, 300)
      } else {
        setIsFocused(false)
      }
    }

    if (el) {
      el.addEventListener('focus', onFocus)
      el.addEventListener('blur', onBlur)
    } else {
      console.log('el not ready')
    }

    return () => {
      if (el) {
        el.removeEventListener('focus', onFocus)
        el.removeEventListener('blur', onBlur)
      }
    }
  }, [ref.current, isEditing])

  return {
    ref,
    focus: () => ref.current.focus(),
    blur: () => ref.current.blur(),
  }
}
