import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Link } from 'react-router-dom'
import * as router from 'react-router'
import styled from 'styled-components'
import { useCollection } from '../contexts/CollectionContext'
import { CenteredMessage } from './misc/CenteredMessage'
import { Page } from './misc/Page'
import { Brand } from './misc/Brand'
import { Logo } from './misc/Logo'
import { UserBar } from './misc/UserBar'
import { auth } from '../utils/auth'

const Welcome = styled.main`
  font-size: min(5vmin, 3rem);
  line-height: 1em;
  font-weight: 200;
  position: relative;
  padding-bottom: 10vmin;
  
  @media (min-width: 45rem) { 
    font-size: min(5vmin, 2.5rem);
  }

  flex: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;

  &, * {
    color: #565656;
  }
`

const Hint = styled.section`
  color: #666;
  padding: 0 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: min(5vmin, 1.5rem);
`

const StyledBrand = styled(Brand)`
  display: block;
`

const StyledLogo = styled(Logo)`
  margin-top: 0.5em;
  font-size: 2.5em;
`

const TagLines = styled.section`
  margin-top: 3em;
  font-size: 0.7em;
  letter-spacing: -0.04em;

  > * {
    margin-bottom: 0.4em;

    &:last-child {
      margin-top: 1.5em;
      font-size: 1.4em;
    }
  }
`

const StyledPage = styled(Page)`
  nav {
    padding-top: 0.9em !important;
  }
`

const Line = styled.div``

export const Home = () => {

  return (
    <StyledPage footer={true} simple>
      { !auth.isLoggedIn && <UserBar spread /> }
      <Welcome>
        <Link to="/p/about">
          <span>this</span> is
          <StyledBrand size="2.5em" />

          <StyledLogo color="var(--slick-pink)" />
        </Link>

        <TagLines>
          <Line>no ads</Line>
          <Line>no fees</Line>
          <Line>no comments</Line>
          <Line>just pure creation.</Line>
        </TagLines>
      </Welcome>
    </StyledPage>
  )
}
