import { h } from 'preact'
import { memo } from 'preact/compat'
import * as router from 'react-router'
import { useEffect, useState } from 'preact/hooks'
import styled from 'styled-components'
import { Controls } from './Controls'
import { Group } from './Group'
import { Page } from '../misc/Page'
import { PAGE_TRANSITIONS } from '../../constants'
import { setDocumentTitle } from '../../utils/setDocumentTitle'
import { useSearch } from '../../contexts/SearchContext'
import { useSettings } from '../../contexts/SettingsContext'

const StyledDetailsPage = styled(Page)`
  transition: opacity ${PAGE_TRANSITIONS} ease, transform ${PAGE_TRANSITIONS} ease;
  // overflow-y: hidden;
  opacity: ${props => props.visible ? 1 : 0.5};
  // z-index: 0;

  &.fullscreen {
    &:after {
      content: 'matt mode';
      z-index: 1;
      font-size: 3em;
      position: fixed;
      bottom: 0.2em;
      right: 0.2em;
      color: rgba(0,0,0,0.2);
      color: var(--text-color);
      opacity: 0.1;
    }
  }

  
`

export const DetailsPage = memo(({ id, visible, collection }) => {
  const { pageTransitions, setPageTransitions, fullscreen } = useSettings()
  let navigate = router.useNavigate()
  let { results } = useSearch()
  const [ targets, setTargets ] = useState([])
  let [ prev, target, next ] = targets

  useEffect(() => {
    if (id) {
      collection
        .getItem(id)
        .then(item => {
          setTargets([
            item.getPrev(results),
            item,
            item.getNext(results)
          ])
        })
        .catch(err => {
          navigate(`/${collection.id}`)
        })
    }
  }, [id, collection, results])

  useEffect(() => {
    if (target) {
      setDocumentTitle(target.title || target.id, collection.title)
    }
  }, [target])

  useEffect(() => {
    let left = ['ArrowLeft', 'Numpad4']
    let right = ['ArrowRight', 'Numpad6']

    let keypressListener = (e) => {
      const { key, code } = e
      const inputs = ['input', 'select', 'button', 'textarea']
      const { activeElement } = document
      const { x } = activeElement.getBoundingClientRect()

      // console.log('activeElement', document.activeElement, document.activeElement.getBoundingClientRect())
      if (inputs.includes(document.activeElement.tagName.toLowerCase()) && x > 0) {
        return false
      }

      // console.log('keypressListener firing', { key, code })
      if (left.includes(code)) {
        setPageTransitions(true)
        // console.log('navigate left', prev && prev.path)
        prev ? navigate(prev.path) : navigate(collection.path)
      } else if (right.includes(code)) {
        setPageTransitions(true)
        // console.log('navigate right', next && next.path)
        next ? navigate(next.path) : navigate(collection.path)
      }
    }

    // setPageTransitions(true)
    window.addEventListener('keydown', keypressListener)

    if (!visible) {
      window.removeEventListener('keydown', keypressListener)
    }

    return () => {
      window.removeEventListener('keydown', keypressListener)
    }
  }, [visible, targets, id])

  if (!target) {
    return null
  }

  return (
    <StyledDetailsPage 
      visible={visible} 
      header={false} 
      footer={false} 
      padding={false} 
      className={`details-group ${fullscreen ? 'fullscreen' : ''}`}
      >
      <Controls prev={prev} next={next} collection={collection} />
      <Group targets={targets} items={results} />
    </StyledDetailsPage>
  )
})