import { h } from 'preact'
import styled from 'styled-components'
import { randomItem } from 'supergeneric/collections'

import { isMobile } from '../../utils/isMobile'
import { Brand } from './Brand'
import { Logo } from './Logo'
import { Link } from './NoFocusLink'


const StyledPoweredBy = styled.section`
  text-transform: none;
  opacity: ${props => props.autoclose ? '0.5' : 1};
  text-align: right;
  flex: 0 auto;
  transition: all 0.1s ease-in-out;
  margin: 0;
  transition: all 0.3s ease;
  font-size: 1rem;
  display: inline-block;

  span {
    display: inline-block;
    overflow-x: hidden;
    max-width: ${props => props.autoclose ? '0' : '20rem'};
    max-height: 1.5em;
    vertical-align: bottom;
    white-space: nowrap;
    margin-top: -0.2em;
    transition: all 0.3s ease;
  }

  a {
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > svg {
      margin-top: -0.1em;
    }
  }

  img {
    vertical-align: middle;
    margin-top: -0.15em;
  }

  &:hover {
    opacity: 1;

    span {
      max-width: 20rem;
    }
  }
`

const StyledPreText = styled.span`
  font-style: normal;
  font-size: 0.75em;
  margin-right: 0.5em;
  opacity: 0.9;
  line-height: 1.65em;
  letter-spacing: 0.02em;
  overflow-y: hidden;
`


const StyledBrand = styled.span`
  position: relative;
  top: -0.05em;

  > svg {
    margin-left: ${props => props.center ? '0.4em' : 0};
  }
`

const StyledLogo = styled(Logo)`
  position: relative;
  top: -0.1em;
  // color: blue;
  overflow: visible;
`

export const PoweredBy = ({
  autoclose = true,
  center = false,
  ...props
}) => {
  const pretext = randomItem([
    'human equality =',
    'powered by love, harmony, and',
    'lions, and tigers, and',
    'tolerance and empathy is',
    'Katiya is',
    'ad-free content is',
    'fast-loading sites are seriously',
  ])

  return (
    <StyledPoweredBy autoclose={autoclose && !isMobile()} center={center} {...props}>
      <Link to="/">
        <StyledPreText>{ pretext }</StyledPreText>
        <StyledLogo className="logo" color="var(--text-color)" />
        <StyledBrand className="slick" center={autoclose}><Brand size="0.8em" color="var(--text-color)" /></StyledBrand>
      </Link>
    </StyledPoweredBy>
  )
}
