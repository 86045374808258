import { h } from 'preact'
import { memo } from 'preact/compat'
import styled from 'styled-components'
import { MOBILE_MAXWIDTH, MAX_IMAGE_SIZE, PAGE_TRANSITIONS } from '../../constants'
import { useCollection } from '../../contexts/CollectionContext'
import { useSettings } from '../../contexts/SettingsContext'

const ActualImage = styled.object`
  width: 100%;
  left: 0;
  opacity: 0.95;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const FullSizeImage = styled(PreviewImage)`
  position: absolute;
`

const ImageGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 2em;
  cursor: pointer;

  &:not(.fullscreen) {
    @media (min-width: ${MOBILE_MAXWIDTH}) {
      ${
        props => !props.horizontal
        ? `float: left;
          margin-right: 2em;
          width: min(55vh, 50vw);`
        : `
          max-height: 70vh;
          overflow: hidden;
          `
      }
    }
  }  

  &.fullscreen {
    overflow: auto;
    margin-bottom: 0;
    align-items: stretch;

    & > img {
      height: auto;
      object-fit: fit;

      &:after {
        content: '';
        padding-top: 100%;
      }
    }
  }
`

export const ImageWithPreview = memo(({ item, visible }) => {
  const { getImagePath, getPreviewPath } = useCollection()
  const { fullscreen, setFullscreen } = useSettings()

  const toggleFullscreen = () => setFullscreen(!fullscreen)

  return (
    <ImageGroup
      id={item.id}
      horizontal={item.isHorizontal} 
      ratio={item.ratio} 
      className={`image ${fullscreen ? 'fullscreen' : ''}`} 
      onClick={toggleFullscreen}
      >
      <PreviewImage
        src={item.previewPath}
        alt={item.name || item.id}
        />
      <FullSizeImage
        src={item.imagePath}
        alt={item.name || item.id}
        />
    </ImageGroup>
  )
})

