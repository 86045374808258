import { h } from 'preact'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { auth } from '../../utils/auth'
import { useSearch } from '../../contexts/SearchContext'
import { useCollection } from '../../contexts/CollectionContext'
import { lazy, Suspense } from 'preact/compat'
const EditField = lazy(() => import('../Admin/Misc/EditField'))

const StyledKeywords = styled.section`
  color: var(--background-color);
  font-size: 0.7em;
  display: flex;
  flex-flow: row wrap;
  text-transform: uppercase;
  font-weight: 200;

  &:not(:first-child) {
    margin-top: 1.8rem;
  }

  &::before {
    content: 'keywords';
    background-color: var(--text-color);
    opacity: 0.8;
    padding: 0.25em 0.45em 0.2em;
    margin-right: 0.6em;
    white-space: nowrap;
    margin-bottom: 0.5em;
  }
`

const Keyword = styled(Link)`
  color: var(--background-color);
  background-color: var(--text-color);
  opacity: 0.4;
  padding: 0.25em 0.45em 0.2em;
  margin-bottom: 0.5em;

  &:not(:last-child) {
    margin-right: 0.6em;
  }

  &:hover {
    background-color: #bbb;
  }
`

const splitKeywords = (str = '') => str.replace(/[,\s;]+/g, ' ').split(' ')
const stringifyKeywords = (keywords = []) => keywords.join(', ')

export const Keywords = ({ item }) => {
  const { setSearchTerms } = useSearch()
  const { collection } = useCollection()
  const keywords = (item.toJSON().keywords || []).filter(k => k)

  const searchSameTerm = term => setSearchTerms(term)

  const content = keywords.length
                ? <StyledKeywords className="keywords">
                    {
                      keywords.map((keyword, i) =>
                        <Keyword
                          key={i}
                          to={collection.path}
                          onClick={() => searchSameTerm(keyword)}
                          >
                          { keyword }
                        </Keyword>
                      )
                    }
                  </StyledKeywords>
                : null

  if (auth.isLoggedIn) {
    return (
      <Suspense>
        <EditField
          item={item}
          path="keywords"
          transform={stringifyKeywords}
          placeholder="Add searchable keywords here"
          updateHandler={keywords => item.update({ keywords: splitKeywords(keywords) })}
          textStyle={`
            font-size: 1em;
            line-height: 0.9em;
            // letter-spacing: -0.05em;
            text-transform: lowercase;
            padding: 0.3em 0.3em 0.25em;
            font-weight: 200;
          `}
          label="searchable keywords"
          // tooltip="this may only be visible during the alpha for testing purposes"
          >
          { content }
        </EditField>
      </Suspense>
    )
  }

  return content
}