import { fetcher } from './DataFetcher'
import { authenticated } from '../utils/authenticated'
import { CollectionItem } from './CollectionItem'
import { Editable } from './Editable'
import { auth } from '../utils/auth'
import { SlickArray } from 'slick-array'

export class Collection extends Editable {
  isLoaded = false
  isLoading = true

  constructor(id, options = {}) {
    if (!id) {
      throw new Error('Cannot create a Collection without a slug or hash (e.g. "krwhitley")')
    }
    const { onChange } = options
    super({ id })
    this.$.type = 'collection'
    this.onChange = onChange
    this.items = new SlickArray({ 
      as: item => new CollectionItem(item, this),
      by: 'id',
    })
  }

  get allChanges() {
    const changes = this.items.filter(i => i.isDirty).map(i => i.changeAction)

    if (this.isDirty) {
      changes.push(this.changeAction)
    }

    return changes.length && changes
  }

  getItem(id, group = this.items) {
    return new Promise((respond, reject) => {
      if (this.isLoaded) {
        respond(this.items.by.id[id])
      } else {
        this.load().then(() => {
          const item = this.items.by.id[id]

          if (item) {
            respond(item)
          } else {
            reject(`Could not fetch item ${id}`)
          }
        })
      }
    })
  }

  getDetails(id) {
    return this.getItem(id)
      .then(item => item.getDetails())
      .catch(err => console.error(`Could not fetch details for ${id}`))
  }

  load() {
    console.log('fetching collection data for', this.id)
    this.isLoading = true

    return fetcher
      .get(`/collections/${this.id}`, undefined, { nocache: auth.isLoggedIn })
      .then(details => {
        if (!details) {
          throw new Error(`No details returned for collection ${this.id}`)
        }

        const { images = [], path, ...rest } = details
        Object.assign(this, rest)

        // console.log({ images })
        // let { name, hash, slug, images } = images
        // Object.assign(this, { name, hash, slug })

        // add each item to list
        this.items.add(...images)

        this.isLoading = false
        this.isLoaded = true

        // console.log('Collection', this)
        return images
      })
      .catch(err => {
        this.notFound = true
        throw new Error(`Collection "${this.id}" not found`)
      })
  }

  get path() {
    return `/${this.id}`
  }

  async savePendingChanges() {
    const changes = this.allChanges
    console.log('saving pending changes (class)', changes)

    return authenticated.fetch(`/bulk-edit`, {
      method: 'POST',
      body: JSON.stringify(changes),
    })
    .then(updated => {
      console.warn('bulk action response', updated)
      this.persistChanges()
      this.items.filter(i => i.isDirty).forEach(image => image.persistChanges())
      return this
    })
    .catch(err => console.error(err))
  }
}
