import { h, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { randomItem } from 'supergeneric/collections'
import { useCollection } from '../../contexts/CollectionContext'
import { setDocumentTitle } from '../../utils/setDocumentTitle'
import {
  PAGE_TRANSITIONS,
} from '../../constants.js'
import { DetailsPage } from '../DetailsPage'
import { IndexPage } from '../IndexPage'
import { Missing } from '../misc/Missing'
import { UserBar } from '../misc/UserBar'
import { Page } from '../misc/Page'
import { auth } from '../../utils/auth'
import { isMobile } from '../../utils/isMobile'
import { Loading } from '../Admin/Misc/Loading'

// import { theme } from './example-theme'

const StyledIndexAndDetails = styled.main`
  background-color: var(--background-color);
  overflow-x: hidden;
  width: 200%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all ${PAGE_TRANSITIONS} ease;
  transform: translate3D(${props => props.showDetails ? '-50%' : 0}, 0, 0);

  input, textarea {
    color: var(--text-color);
  }

  > * {
    width: 50%;
    left: 50%;

    &:first-child {
      left: 0;
    }
  }
`

const UserStyledPage = styled(Page)`
  ${p => p.theme || ''}
`

export const IndexAndDetails = () => {
  let { collectionid, id } = useParams()
  let { collection, setCollection, isLoading, notFound } = useCollection()

  if (isLoading) {
    return <Loading />
  }

  if (notFound) {
    return <Missing />
  }

  const theme = collection?.toJSON({ withChanges: true }).theme

  useEffect(() => {
    setCollection(collectionid)
  }, [collectionid])

  if (!collection || collection.id !== collectionid) return null

  useEffect(() => {
    setDocumentTitle(collection.title)
  }, [collectionid, id])

  return (
    <UserStyledPage header={auth.isLoggedIn} padding={false} footer={false} theme={theme}>
      {
        collection
        ? <StyledIndexAndDetails className={`index-and-details${isMobile() ? ' mobile' : ''}`} showDetails={id}>
            <IndexPage
              visible={!id}
              items={collection.items}
              />
            <DetailsPage visible={id} id={id} collection={collection} />
          </StyledIndexAndDetails>
        : <Missing />
      }
    </UserStyledPage>
  )
}
