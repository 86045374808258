import { h } from 'preact'
import { memo } from 'preact/compat'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useHasBeenVisible } from '../../hooks/useHasBeenVisible'
import { MOBILE_MAXWIDTH } from '../../constants'
import { useCollection } from '../../contexts/CollectionContext'
import { useSettings } from '../../contexts/SettingsContext'

const StyledGridItem = styled.div`
  display: flex;
  background-color: var(--background-color);
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  color: black;
  text-decoration: none;
  opacity: 0.95;
  position: relative;
  text-align: right;
  transition: opacity 0.3s ease;
  overflow: hidden;

  overflow: hidden;
  position: relative;
  outline: 0.35rem solid #fff;
  transition: all 0.2s ease;

  ${props => !props.visible ? 'display: none;' : ''}

  &:hover {
    opacity: 1;
    z-index: 100;
    transform: scale(1.04);
    box-shadow: 0.2em 1em 2em rgba(0,0,0,0.6);

    span {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    background-color: #eee;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  // &:hover {
  //   opacity: 0.85;

  //   header {
  //     opacity: 1;
  //   }
  // }
`

const Title = styled.span`
  font-size: max(2.5vmin, 1.4rem);
  position: absolute;
  left: 0;
  right: 0;
  padding: 0.5rem;
  line-height: 0.95em;
  letter-spacing: -0.03em;
  overflow: hidden;
  transition: all 0.2s ease;
  text-overflow: ellipsis;
  font-weight: 200;
  text-shadow: 0 0 1em rgba(0,0,0,1);
  background-color: rgba(0,0,0,0.5);
  opacity: 0;

  z-index: 1;
  color: white;

  @media (max-width: ${MOBILE_MAXWIDTH}) {
    font-size: max(5vmin, 0.8rem);
  }
`

const HiddenLink = styled.span`
  display: none;
`

export const GridItem = memo(({ item, visible }) => {
  const { ref, hasBeenVisible } = useHasBeenVisible()
  const { getPreviewPath, getDetailsPath } = useCollection()
  const { setPageTransitions } = useSettings()

  const clickHandler = e => {
    e.target.blur()
    setPageTransitions(false)
  }

  return (
    <StyledGridItem
      ref={ref}
      as={Link}
      to={item.path}
      onClick={clickHandler}
      visible={visible}
      wide={item.isWide}
      tall={item.isTall}
      ariaLabel={item.title || item.id}
      className={`item ${item.orientation}`}
      >
      {
        hasBeenVisible
        ? <img src={item.previewPath} alt={item.name || item.id} />
        : null
      }
      {
        item.title && <Title className="title">{ item.title }</Title>
      }
    </StyledGridItem>
  )
})