import { h } from 'preact'
import styled from 'styled-components'
import { Footer } from './Footer'

const StyledLorem = styled.div`
  margin: 2em;
  padding: 2em;
  background-color: #eee;
`

export const Lorem = () => {
  return (
    <StyledLorem>
      <h1>Lorem Ipsum</h1>
      <Footer />


    </StyledLorem>
  )
}