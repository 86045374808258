import { h, render } from 'preact'
import App from './App'
// import * as serviceWorker from './serviceWorker'
import { auth } from './utils/auth'

// enable explain mode
const root = document.getElementById('root')
if (window.location.search.includes('explain')) {
  root.className = 'explain'
}

const bootstrap = async () => {
  await auth.waitForRefresh()

  render(<App />, root)
}

bootstrap()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
