import { h } from 'preact'
import styled from 'styled-components'

import { auth } from '../../utils/auth'
import { lazy, Suspense } from 'preact/compat'
const EditField = lazy(() => import('../Admin/Misc/EditField'))

const StyledSubtitle = styled.h2`
  color: var(--text-color);
  opacity: 0.4;
  font-weight: 200;
  font-size: 1.17em;
  margin-bottom: 0;

  &:not(:first-child) {
    margin-top: 0.1em;
  }
`

export const Subtitle = ({ item }) => {
  const content = item.toJSON().subtitle
                ? <StyledSubtitle landscape={item.isLandscape} className="subtitle">
                    { item.toJSON().subtitle }
                  </StyledSubtitle>
                : null

  if (auth.isLoggedIn) {
    return (
      <Suspense>
        <EditField
          item={item}
          path="subtitle"
          placeholder="searchable subtitle, like a location"
          updateHandler={subtitle => item.update({ subtitle })}
          textStyle={`
            font-weight: 200;
            font-size: 1.17em;
            margin-bottom: 1.5em;
            line-height: 0.9em;
            letter-spacing: -0.05em;
            padding: 0.3em 0.3em 0.25em;
          `}
          >
          { content }
        </EditField>
      </Suspense>
    )
  }

  return content
}