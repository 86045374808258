import { h } from 'preact'
import styled from 'styled-components'
import { Title } from './Title'
import { Subtitle } from './Subtitle'
import { Keywords } from './Keywords'

const StyledHeader = styled.header`
  font-size: max(1em, 1.5vmax);
  display: flex;
  flex-flow: column;

  &:not(:empty) {
    margin-bottom: 1.5em;
  }
`

export const Header = ({ item }) => {
  return (
    <StyledHeader>
      <Title item={item} />
      <Subtitle item={item} />
      <Keywords item={item} />
    </StyledHeader>
  )
}