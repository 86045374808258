import { h } from 'preact'
import styled from 'styled-components'
import { MOBILE_MAXWIDTH } from '../../constants'

import { auth } from '../../utils/auth'
import { lazy, Suspense } from 'preact/compat'
const EditField = lazy(() => import('../Admin/Misc/EditField'))

const StyledTitle = styled.h1`
  font-size: 2.3em;
  column-span: ${props => props.landscape ? 'all' : 'none'};
  line-height: 0.96em;
  letter-spacing: -0.05em;
  margin-bottom: 0;

  @media (min-width: ${MOBILE_MAXWIDTH}) {
    ${
      props => !props.landscape
      ? 'padding-right: 0.5em;'
      : null
    }
  }
`

export const Title = ({ item }) => {
  const content = item.toJSON().title
                ? <StyledTitle landscape={item.isLandscape} className="title">
                    { item.toJSON().title }
                  </StyledTitle>
                : null

  if (auth.isLoggedIn) {
    return (
      <Suspense>
        <EditField
          item={item}
          path="title"
          placeholder="This image deserves a cool title"
          updateHandler={title => item.update({ title })}
          textStyle={`
            font-weight: 200;
            font-size: 2.3em;
            line-height: 0.9em;
            letter-spacing: -0.05em;
            padding: 0.18em 0.17em 0.18em;
          `}
          >
          { content }
        </EditField>
      </Suspense>
    )
  }

  return content
}
