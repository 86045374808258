import { useEffect, useState } from 'preact/hooks'
import { useCollection } from '../contexts/CollectionContext'

export const useDetails = (item) => {
  let { collection } = useCollection()
  let [ details, setDetails ] = useState({})

  useEffect(() => {
    if (item) {
      collection.getDetails(item.id).then(setDetails)
    }
  }, [item, collection])

  // useEffect(() => {
  //   console.log('details for', item.id, 'changed', details)
  // }, [details])

  return details
}