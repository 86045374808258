import { h } from 'preact'
import { memo } from 'preact/compat'
import styled from 'styled-components'
// import { useUnmount } from '../../hooks/useUnmount'
import { useDetails } from '../../hooks/useDetails'
import { ImageWithPreview } from './ImageWithPreview'
import { Header } from './Header'
import { Story } from './Story'
import { PoweredBy } from '../misc/PoweredBy'
import {
  CONTENT_PADDING,
  MOBILE_MAXWIDTH,
  MOBILE_PADDING,
  PAGE_TRANSITIONS,
  MOBILE_CONTENT_PADDING,
} from '../../constants'
import { useSettings } from '../../contexts/SettingsContext'
// import { useScrollTop } from '../../hooks/useScrollTop'

const StyledPane = styled.article`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transform: translate3D(${props => props.offset * 100}%, 0, 0) scale(${props => props.middle ? 1 : 0.9});
  transition: all ${props => props.transitions ? PAGE_TRANSITIONS : '0s'} ease;
  transition-property: transform, opacity;
  opacity: ${props => props.middle ? 1 : 0.5};
  padding: ${CONTENT_PADDING};
  overflow-y: scroll;

  &.fullscreen {
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  @media (max-width: ${MOBILE_MAXWIDTH}) {
    padding: 1.2rem 0 0 0;
  }
`

const StyledTextContent = styled.section`
  @media (max-width: ${MOBILE_MAXWIDTH}) {
    padding: calc(${MOBILE_CONTENT_PADDING} * 0.35) ${MOBILE_CONTENT_PADDING} ${MOBILE_CONTENT_PADDING} ${MOBILE_CONTENT_PADDING};
  }
`

const StyledPoweredBy = styled(PoweredBy)`
  float: right;
  padding-bottom: 2em;
`

export const Pane = memo(({ item, middle, items }) => {
  let offset = item.getIndex(items)
  const details = useDetails(item)
  const { group, id, story } = details
  const { pageTransitions, fullscreen } = useSettings()

  return (
    <StyledPane transitions={pageTransitions} offset={offset} middle={middle} className={`details ${fullscreen ? 'fullscreen' : ''}`}>
      <ImageWithPreview item={item} visible={middle} />
{/*
      <pre>
        { JSON.stringify(item.toJSON(), null, 2) }
      </pre> */}

      { 
        !fullscreen && <StyledTextContent>
          <Header item={item} />
          <Story item={item} story={story} />
          <StyledPoweredBy className="brand" />
        </StyledTextContent>
      }
    </StyledPane>
  )
})
