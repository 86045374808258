import { h } from 'preact'

export const Logo = ({
  color = "var(--slick-pink)",
  size = "1em",
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" {...props} color={color}>
      <path fill={color} data-name="slick.af logo 1" class="cls-1" d="M0 0H64V64H0V0ZM9 9V37h37v9H18V41H9V55H55V27H18V18H46v5H55V9H9Z" fill-rule="evenodd" />
    </svg>
  )
}

