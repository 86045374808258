import { h, createContext } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { authenticated } from '../../../utils/authenticated'
import { delay } from '../../../utils/delay'
import { useCollection } from '../../../contexts/CollectionContext'
import { useSettings } from '../../../contexts/SettingsContext'

export const AdminContext = createContext({})

export const AdminProvider = ({ children }) => {
  const [ state, setState ] = useState({
    profile: undefined,
    isLoaded: false,
    isLoading: false,
  })
  const { isEditing } = useSettings()
  const { collection } = useCollection()

  const update = (fn) => {
    const updated = fn ? fn(state) : state
    setState({ ...updated, timestamp: new Date })
  }

  useEffect(async () => {
    setState(state => ({ ...state, isLoading: true }))
    
    const [
      profile,
      ownership,
    ] = await Promise.all([
      authenticated.fetch('/my/profile'),
      authenticated.fetch('/my/ownership'),
      // delay(5000),
    ])

    profile.aliases = ownership.aliases || [],
    profile.collections = ownership.collections || [],
    profile.themes = ownership.themes || []
    profile.cloud = ownership.cloud

    setState({
      profile,
      isLoaded: true,
      isLoading: false,
    })
  }, [])

  const profile = state.profile
  const isOwner = profile && profile.id && collection && profile.collections.map(c => c.id).includes(collection.id)
  const isEditable = isOwner && isEditing

  window.profile = profile

  return (
    <AdminContext.Provider
      value={{
        isOwner,
        isEditable,
        ...state,
        ...profile,
        update,
      }}>
      { children }
    </AdminContext.Provider>
  )
}

export const useProfile = () => useContext(AdminContext)

export default AdminProvider