export const isMobile = () => {
  // console.log({
  //   orientation: window.orientation,
  //   userAgent: navigator.userAgent,
  //   ontouchstart: window.ontouchstart,
  //   MaxTouchPoints: navigator.MaxTouchPoints,
  //   msMaxTouchPoints: navigator.msMaxTouchPoints,
  // })

  return (typeof window.orientation !== 'undefined')
  || (navigator.userAgent.indexOf('IEMobile') !== -1)
  || ('ontouchstart' in window)
  || (navigator.MaxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0)
}
