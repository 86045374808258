import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { memo, lazy, Suspense } from 'preact/compat'
import styled from 'styled-components'
import { Page } from '../misc/Page'
import { Grid } from './Grid'
import { Title } from './Title'
import { NoResults } from './NoResults'
import { PAGE_TRANSITIONS } from '../../constants'
import { useSearch } from '../../contexts/SearchContext'
import { useSettings } from '../../contexts/SettingsContext'
import { SearchBox } from './SearchBox'
import { SearchLink } from './SearchLink'
import { UserBar } from '../misc/UserBar'
import { auth } from '../../utils/auth'
import { useProfile } from '../Admin/Contexts/AdminContext'
import { Settings } from './Settings'

const StyledPage = styled(Page)`
  font-size: min(10vw, 4.5rem);
  z-index: 1;
  padding-top: 0.1em;
`

const StyledUserBar = styled(UserBar)`
  --padding-sides: 1.7em;
  padding: 1.1em var(--padding-sides);
  width: calc(100% - var(--padding-sides) * 2);
`

export const IndexPage = memo(({ visible, items, showOnly = items }) => {
  const { searchTerms, setSearchTerms, results, searchVisible } = useSearch()
  const { fullscreen, setFullscreen, isEditing } = useSettings()
  const { isEditable } = useProfile()

  useEffect(() => {
    if (visible && fullscreen) {
      setFullscreen(false)
    }
  }, [visible, fullscreen])

  const settingsBlock = isEditable && (
    <Suspense>
      <Settings />
    </Suspense>
  )

  return (
    <StyledPage scrollToTop={false} visible={visible} className="index">
      { !auth.isLoggedIn && <StyledUserBar /> }
      <Title />
      { !isEditing && <SearchLink /> }
      <SearchBox visible={visible} />

      { settingsBlock }

      {
        results.length || !searchTerms
        ? <Grid items={items} showOnly={results} />
        : <NoResults />
      }
    </StyledPage>
  )
})
