import styled from 'styled-components'

const DISABLED_COLOR = '#e5e5e5'

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: ${props => props.disabled ? DISABLED_COLOR : '#efefef'};
  text-decoration: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.5;
  }
`