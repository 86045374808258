
import { h } from 'preact'
import styled from 'styled-components'
import { randomItem } from 'supergeneric/collections'
import { CenteredMessage } from './CenteredMessage'
import { PoweredBy } from './PoweredBy'
import { Page } from './Page'
import { UserBar } from './UserBar'

const StyledPoweredBy = styled(PoweredBy)`
  margin-top: 2em;
`

const BigText = styled.section`
  letter-spacing: -0.04em;
`

const StyledCenterMessage = styled(CenteredMessage)`
  letter-spacing: -0.04em;

  > *:last-child {
    margin-bottom: 10rem;
  }
`

const StyledPage = styled(Page)`
  margin: 0 2em;
`

export const Missing = () => {
  const messages = [
    `[ crickets ]`,
    `...but there's nothing here!`,
    `...are you sure about that?`,
    `it would have been awesome if that page existed...`,
  ]

  return (
    <StyledPage footer simple>
      <UserBar spread />
      <StyledCenterMessage>
        <BigText>{ randomItem(messages) }</BigText>
        <StyledPoweredBy center />
      </StyledCenterMessage>
    </StyledPage>
  )
}