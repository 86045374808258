import { h, Fragment} from 'preact'

export const Lorem = () => {
  return (
    <Fragment>
      {
        Array(5)
          .fill('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc libero, venenatis id diam imperdiet, sollicitudin porttitor neque. Quisque a sapien sit amet felis laoreet molestie nec vel est. Integer porttitor elit odio, in facilisis libero dictum eu. Cras at eros nec velit maximus ornare. Phasellus sit amet pellentesque ligula, vel rutrum nunc. Donec vestibulum in eros ac ultrices. Nulla blandit mauris ligula, et porttitor tellus mollis sit amet. Mauris tincidunt sollicitudin iaculis. Aenean blandit justo sit amet tellus imperdiet, accumsan porta erat aliquam. In enim felis, feugiat in consequat at, dictum ut lectus. Integer tincidunt blandit elementum. Maecenas blandit congue sem varius volutpat. Duis a auctor orci. Nunc blandit condimentum elit. Aliquam molestie, nibh molestie consectetur commodo, orci eros vehicula urna, vel dignissim ante tellus eu ante. Nam in lacus nibh.')
          .map((v, i) => <p key={i}>{ v }</p>)
      }
    </Fragment>
  )
}
