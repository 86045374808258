import { h } from 'preact'
import { memo } from 'preact/compat'
import { lazy, Suspense } from 'preact/compat'
import styled from 'styled-components'
import { useCollection } from '../../contexts/CollectionContext'
import { useProfile } from '../Admin/Contexts/AdminContext'

const EditField = lazy(() => import('../Admin/Misc/EditField'))

const StyledTitle = styled.h1`
  color: var(--text-color);
  font-weight: lighter;
  font-family: sans-serif;
  letter-spacing: -0.05em;
  margin: 0.4em 0.5em 0.6em 3.1em;
  text-align: right;
  line-height: 0.95em;
  font-weight: 200;
  flex: 0;
  font-size: 1em;
  max-width: 60rem;
  align-self: flex-end;
`

export const Title = memo(() => {
  const { collection } = useCollection()
  const { isEditable } = useProfile()
  const content = <StyledTitle className="collection-title">
                    { collection.title || collection.id }
                  </StyledTitle>

  if (isEditable) {
    return (
      <Suspense>
        <EditField
          item={collection}
          path="title"
          placeholder="Collection Name"
          updateHandler={title => collection.update({ title })}
          textStyle={`
            font-weight: lighter;
            font-size: 1em;
            line-height: 0.95em;
            letter-spacing: -0.05em;
            margin: 0.2em 0.2em 0.4em 1.5em;
            padding: 0.1em;
            text-align: right;
          `}
          >
          { content }
        </EditField>

        <EditField
          item={collection}
          path="theme"
          placeholder="Theme CSS/SCSS (optional)"
          updateHandler={theme => collection.update({ theme })}
          textStyle={`
            font-weight: lighter;
            font-size: 1rem;
            line-height: 0.95em;
            letter-spacing: -0.05em;
            margin: 0 0.9rem 1.5rem 6.7rem;
            padding: 1em;
            white-space: pre-line;
            font-family: Courier;
          `}
          >
        </EditField>
      </Suspense>
    )
  }

  return content
})
