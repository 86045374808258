import styled from 'styled-components'
import { Centered } from './Centered'

export const CenteredMessage = styled(Centered)`
  font-size: 7vmin;
  font-weight: 200;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  // width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 100;
  color: ${props => props.color || '#999'};
`
